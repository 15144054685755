/** @jsxImportSource react */
import clsx from 'clsx'
import { PropsWithChildren, useState } from 'react'
import FontAwesomeIcon from './FontAwesomeIcon'

export default function HeaderMobileMenuSubmenu({ title, items }: any) {
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)

  return (
    <li className={clsx('u-flex u-flex-col', !open && 'u-border-b')}>
      <button type="button" className="action u-flex u-w-full u-items-center u-justify-between u-py-3" onClick={toggle}>
        <span dangerouslySetInnerHTML={{ __html: title }} />
        <div className="u-grid u-h-6 u-w-6 u-place-content-center">
          <FontAwesomeIcon icon={open ? 'chevron-up' : 'chevron-down'} />
        </div>
      </button>
      {open && (
        <ol role="list" className="flow flow-sm u-bg-light u-p-4">
          {items.map((item: any, idx: number) => (
            <li key={idx}>
              <a href={item.link.url} className="action u-block">
                {item.link.label}
              </a>
            </li>
          ))}
        </ol>
      )}
    </li>
  )
}
